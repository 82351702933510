export const formatTokenAmount = (amount?: string | number | null): string =>
  amount ? `${amount}`.replace(/(\.\d{3})\d+/, '$1') : '0';

export const formatSqrTokenAmount = (amount?: string | number | null, decimals = 4): string => {
  const regex = new RegExp(`(\\.\\d{${decimals}})\\d+`, 'g');

  return amount ? String(amount).replace(regex, '$1') : '0';
};

export const formatTokenReward = (amount?: string | number | null, decimals = 3): string => {
  if (!amount) {
    return Number(0).toFixed(decimals);
  }

  const formatterAmount = Number(formatTokenAmount(amount)).toFixed(decimals);

  return String(parseFloat(formatterAmount));
};

export function formatTokenPriceChange(tokenPriceChange: number) {
  if (!tokenPriceChange) {
    return '0%';
  }

  const price = Number(tokenPriceChange.toFixed(2));

  if (Math.ceil(price) === 0) {
    return `${price}%`;
  }

  if (price > 0) {
    return `+${price}%`;
  }

  return `${price}%`;
}
